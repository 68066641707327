import styles from './ProjectDetails.module.css'
import calender from '../../static/calender.svg'
import opensea from '../../static/opensea.svg'
import shards_of_eth from '../../static/shards_of_eth.svg'
import dehidden from '../../static/dehidden.svg'
import { useState, useEffect } from 'react'

export const ProjectDetails = ({showProject}) => {
    
    const [desktopView, setDesktopView] = useState(false)
    const [showfooter, setShowFooter] = useState(false)
    const [detailsLinkActive, setDetailsLinkActive] = useState(false)
    const [descpLinkActive, setDescpLinkActive] = useState(true)

    //listening for window resizing
    useEffect(() => {
        if (window.innerWidth > 991) {
        setDesktopView(true)
        window.addEventListener('resize',() =>{
            if (window.innerWidth > 991) {
                console.log('Desktop View!')
                setDesktopView(true)
                showfooter(true)
            }else{
                console.log('Mobile View!')
                setDesktopView(false)
                showfooter(false)
            }
        })     
        }
    },[desktopView, showfooter, descpLinkActive, detailsLinkActive])

    function switch2Description(){
        setDescpLinkActive(true)
        setDetailsLinkActive(false)
        setShowFooter(false)
    }

    function switch2Details(){
        setDescpLinkActive(false)
        setDetailsLinkActive(true)
        setShowFooter(true)
    }
    //handle link active on click

    function ProjectBody({styles}){
        return (<div className={styles.body}>

            <h1 className={styles.heading}>Shards of Polygon - OG </h1>

           <span className={styles.sub_heading}>An NFT family that consists of memorabilia enclosed in a crystal ether.</span>

           <p className={styles.body_text}>This Shard symbolizes one of Technology's most significant moments embedded in the web3 layer of Ethereum. Shards of Polygon are an emerging crypto asset, as their smart contract design prevents manipulation and duplication in the marketplace. Possessing a Shard grants you access to a set of unlockables powered by Dehidden, a game changing utility tech for NFTs.</p>
       </div> )
    }

    function ProjectFooter({styles}){
       return (<div className={styles.footer}>
            <div className={styles.details}>
                <span>Minted On</span>
                <div className={styles.detail_body}>
                    <img src={calender} alt="" />
                    <span>29th October</span>
                </div>
            </div>
            <div className={styles.details}>
                <span>Active Platform</span>
                <div className={styles.detail_body}>
                    <img src={opensea} alt="" />
                    <span>OpenSea</span>
                </div>
            </div>
            <div className={styles.details}>
                <span>Creator</span>
                <div className={styles.detail_body}>
                    <img src={dehidden} alt="" />
                    <span>Dehidden</span>
                </div>
            </div>
            <div className={styles.details}>
                <span>Collection</span>
                <div className={styles.detail_body}>
                    <img src={shards_of_eth} alt="" />
                    <span>Shards of Eth</span>
                </div>
            </div>
        </div>)
    }
    return (
        <div className={styles.project_details}>
            <div className={styles.head}>
                <div className={styles.btn_container} onClick={() => showProject(false)}>
                 <a className={styles.goback_btn}><i class="fas fa-chevron-left"></i> Back</a>
                </div>
                <div className={styles.head_nav}>
                      <a onClick={switch2Description} style={{ color: descpLinkActive ? '#9281F5': 'white', fontWeight: descpLinkActive ? 'bold':'initial'}} className={styles.nav_link}>Description</a>
                      <a style={{ color: detailsLinkActive ? '#9281F5': 'white' , fontWeight: detailsLinkActive ? 'bold':'initial'}}
                      onClick={switch2Details} className={styles.nav_link}>Details</a>
                </div>
            </div>
            {!desktopView ? (!showfooter ? 
             <ProjectBody styles={styles} />: 
             <ProjectFooter styles={styles} />
             ):
            (<div>
                <ProjectBody styles={styles} />
                <ProjectFooter styles={styles} />
            </div>)
            }
        </div>
    )
}
