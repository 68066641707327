import styles from './MainScreen.module.css'
import { Navbar } from '../../Components/Navbar/Navbar'
import {useState} from 'react'
import { useRef } from 'react'
import polygon_logo from '../../static/polygon_logo.svg'
import PolygonPhygital from '../../static/PolygonPhygital.mp4'
import { Wallet } from '../../Components/Wallet/Wallet'



export const Phyg_MainScreen = () => {
    
    let walletRef = useRef(null)
    const [showWallet, setShowWallet] = useState(false)

    return (
        <div className={styles.Mainscreen}>
                <Navbar />
                <div className={styles.content_container}>
                    <div className={styles.main_nft_display}>
                        <video src={PolygonPhygital} autoPlay loop muted playsInline></video>
                    </div>

                    <div className={styles.main_content}>
                    <img src={polygon_logo} alt="" className={styles.logo_content} />
                        <span>Get a chance Mint this NFT</span>
                        <p>
                        Click on the button below to Mint this NFT for free and get it in your wallet.
                        </p>  
                        <div className={styles.btn_container}>
                            <button className={styles.claim_nft_btn} onClick={() => setShowWallet(true)}>Mint and Claim NFT</button>
                        </div>
                    </div>

                    {/* close wallet logic */}
                {showWallet ? <Wallet forwardedRef={walletRef} closeWallet={() => setShowWallet(false)} setShowWallet={setShowWallet}/>:''}
                </div>
        </div>
    )
}
