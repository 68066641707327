import styles from './How2Connect.module.css'
import { Navbar } from '../../Components/Navbar/Navbar'
import { Wallet } from '../../Components/Wallet/Wallet'
import {useRef,  useState} from 'react'
import {Link} from 'react-router-dom'

export const Phyg_How2Connect = () => {
    const [showWallet, setShowWallet] = useState(false)
    let walletRef = useRef(null)
    return (
        <div className={styles.how2connect}>
            <Navbar />
            {/* close wallet logic */}
            {showWallet ? <Wallet forwardedRef={walletRef} closeWallet={() => setShowWallet(false)} setShowWallet={setShowWallet}/>:''}
            <div className={styles.how2connect_container}>
            <h1 className={styles.heading}>Steps to Setup a Wallet</h1>
            <div className={styles.how2connect_body}>
                <div className={styles.steps}>
                    <div className={`${styles.step} ${styles.step1}`}>
                        <span>1.</span>
                        <p>
                        Install Metamask from below by visiting <a href='https://metamask.io' target="_blank" className={styles.imp_link}>metamask.io</a>  or below <a href='https://metamask.app.link/bxwkE8oF99' target="_blank" className={styles.imp_link}>https://metamask.app.link/bxwkE8oF99</a>
                        </p>
                    </div>
                    <div className={`${styles.step} ${styles.step2}`}>
                        <span>2.</span>
                        <p>Setup your Login password</p>
                    </div>
                    <div className={`${styles.step} ${styles.step3}`}>
                        <span>3.</span>
                        <p>Setup your Account and Secure your SeedPhrase Backup and Keep it securely copied</p>
                    </div>
                </div>    
            </div>
            <div className={styles.how2Connect_footer}>
                <p className={styles.final_note}>
                    Now just click connect button below or use link we sent in Email to connect your wallet and claim NFT
                </p>
                <button className={styles.connect_wallet} onClick={() => setShowWallet(true)} >Connect Wallet</button>
                <Link to='/phygitals/kit/main'><button className={`${styles.go_back_btn} ${styles.floating_gbk_btn }`}> <i class="fas fa-chevron-circle-left"></i></button></Link>
            </div>
         </div>

       
        </div>
    )
}
