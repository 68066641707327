import React from "react";
//IMG imports
import eiffil from '../../../../static/media/eiffil.png' 
import newStyles from './ViewProjectUtility.module.css'
import { Wallet } from '../../Components/Wallet/Wallet'
import {useState} from 'react'
import { useRef } from 'react'

function RenderUtilityItem({data,styles,showProject}){

    return(
        <a key={data.id}  onClick = {() => showProject(true) } key={data.id} target="_blank" rel='noopener' rel="noreferrer">
        <div key={data.id} className={styles.utility}>
          <div className={styles.utility_icon}>
            <img src={data.img_src} alt="" />
          </div>
          <div className={styles.utility_text}>
            <div className={styles.utility_heading}>{data.head}</div>
            <div className={styles.utility_subheading}>
              {data.sub_head}
            </div>
          </div>
        </div>
      </a>
    )
}
function ViewProjectUtility({showProject}) {

    let walletRef = useRef(null)
    const [showWallet, setShowWallet] = useState(false)

   let items = [
    {  
    id:1,      
    img_src:eiffil,
    head:'View Project Details',
    sub_head:'',
   }
]
  return (
    <div className={newStyles.utilities}>
      <div className={newStyles.utilities_container}>
       {items.map(item => <RenderUtilityItem data={item} styles={newStyles} showProject={showProject}/>)}
       <div className={newStyles.wallet_btn_container}>
           <span>Link your wallet</span>  
           <button className={newStyles.connect_wallet_btn} onClick={() => setShowWallet(true)}>Connect Wallet </button>
       </div>
      </div>
        {/* close wallet logic */}
        {showWallet ? <Wallet forwardedRef={walletRef} closeWallet={() => setShowWallet(false)} setShowWallet={setShowWallet}/>:''}
    </div>
  );
}

export default ViewProjectUtility