import styles from './Wallet.module.css'
import metamask from '../../static/metamask.svg'
import wallet_connect from '../../static/wallet_connect.svg'
import { useEffect } from 'react'
import {Link} from 'react-router-dom'

export const Wallet = ({closeWallet,forwardedRef,setShowWallet}) => {

useEffect(() => {
    document.addEventListener('mousedown',(event) => {
        if(forwardedRef.current && !forwardedRef.current.contains(event.target))
         setShowWallet(false)
    })
    console.log(forwardedRef.current, 'forwareded ref from parent!')
}, [])

   return(
        <div className={styles.wallet_component}>
        <div ref={forwardedRef} className={styles.wallet_container}>
           <div className={styles.wallet_head}>
               <h1 className={styles.wallet_heading}>Connect Wallet</h1>
               <a href='#' className={styles.close_wallet_container_btn} onClick={closeWallet}><i class="fas fa-times"></i></a>
           </div> 
           <div className={styles.wallet_body}>
            <p className={styles.wallet_body_note}>*Select your preferable wallet.</p>
            <div className={styles.wallets}>
               <div className={`${styles.wallet} ${styles.meta_mask}`}>
                <img src={metamask}className={styles.walletImg} />
                   <p className={styles.walletName}>Meta Mask</p>
                   <span className={styles.chevron}><i class="fas fa-chevron-right"></i></span>
               </div>
               <div className={`${styles.wallet} ${styles.wallet_connect}`}>
               <img src={wallet_connect}className={styles.walletImg} />
                   <p className={styles.walletName}>Wallet Connect</p>
                   <span className={styles.chevron}><i class="fas fa-chevron-right"></i></span>
               </div>
           </div>
           </div>
           <div className={styles.wallet_footer}>
               <p className={styles.wallet_footer_note}>Haven’t got a crypto wallet yet?</p>
               <Link to='/phygitals/how2connect'><button className={styles.how2connect_btn}>Learn How to Connect</button></Link>
           </div>
           </div>
        </div>
       )
}
