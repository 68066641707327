import styles from './ShareNFT.module.css'
import { Navbar } from '../../Components/Navbar/Navbar'
import PolygonPhygital from '../../static/PolygonPhygital.mp4'
export const Phyg_ShareNFT = () => {
    return (
        <div className={styles.share_nft_container}>
            <Navbar />
            <div className={styles.share_nft}>
            <h1 className={styles.heading}>Here’s your NFT</h1>

            <div className={styles.share_nft_body}>
                <div className={`${styles.share_nft_vid_container} ${styles.shadow}`}>
                    <video src={PolygonPhygital} autoPlay loop muted playsInline></video>
                </div>
                <button className={styles.view_nft_btn}>View NFT</button>
            </div>
            <div className={styles.share_nft_footer}>
                <p className={styles.footer_text}>Share it to social media</p>
                <div className={styles.social_meadia_handles}>
                    <div className={`${styles.social} ${styles.insta}`}><i class="fab fa-instagram"></i></div>
                    <div className={`${styles.social} ${styles.twitter}`}><i class="fab fa-twitter"></i></div>
                    <div className={`${styles.social} ${styles.whatsapp}`}><i class="fab fa-whatsapp"></i></div>
                </div>
            </div>
            </div>
           
        </div>
    )
}
