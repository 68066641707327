import styles from './Navbar.module.css'
import logo from '../../static/logo.svg'
import polygon_logo from '../../static/polygon_logo.svg'

export const Navbar = () => {
    return (
        <div className={styles.navbar}>
           <img src={logo} alt="" className={styles.logo} />
           <img src={polygon_logo} alt="" className={styles.logo} />
        </div>
    )
}
