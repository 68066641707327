import './ShareNFT.css'
import { Navbar } from '../../Components/Navbar/Navbar'
import {Timeline} from '../../Components/Timeline/Timeline'

import { useNavigate } from 'react-router-dom'
import { useModalStore } from '../../../../App'

import { useEffect, useState } from 'react'
import { useEthers } from '@usedapp/core'
import { ethers } from 'ethers'
import { ABI, contractAddress } from '../../../../data/contractInfo'
import axios from 'axios'
const moralisApi = `https://deep-index.moralis.io/api/v2/`


export const ShareNFT = ({plyr}) => {
    const navigate = useNavigate()
    const {setSetC,setSetA,setSetB} = useModalStore()
    const { account, library } = useEthers()
    const [nftUrl, setNftUrl] = useState('')
    useEffect(() => {
        let verification = localStorage.getItem("taskCompleted");
        if (verification === "false" || !verification) {
          navigate("/auth/tasks");
        } else {
        getMyNft()
        setSetA(true)
        setSetB(true) 
        setSetC(true)
        }
    }, [])


    const getMyNft = async () => {
        const url = `${moralisApi}/${account}/nft/?chain=mumbai&format=decimal`
        const res = await axios.get(url, {
            headers: {
                'X-API-Key': 'j2jCbAIkQxWYNyfBohtGynlSHzjohwHDHOZqloZxhlblw3B5eHSztkgiLlgKCL95'
            }
        })
        let results = res.data.result
        let tokenId
        console.log(results)
        results.map(token => {
            if(contractAddress.toLowerCase() === token.token_address){
                console.log(token.token_id)
                tokenId = token.token_id
                return ""
            }
        })
       
        const openseaUrl = `https://testnets.opensea.io/assets/mumbai/${contractAddress}/${tokenId}`
        console.log(openseaUrl)
        setNftUrl(openseaUrl)
    }


    return (
        <div className='share_nft_container'>
            <Navbar />
            <Timeline all_tasks_complete={true} />
            <h1 className="heading">Here’s your NFT</h1>

            <div className="share_nft_body">
                <div className="share_nft_vid_container shadow">
                    <video src={plyr.vid_url} autoPlay loop muted playsInline></video>
                </div>
                <button onClick={() => 
                    navigate(`/rcb/${localStorage.getItem("PLYR")}`)
                } className="view_nft_btn">View NFT</button>
                <br/>
                <button onClick={() =>
                   window.open(nftUrl)
                } className="share_nft_btn">View NFT on OpenSea</button>
            </div>
           
            <div className="share_nft_footer">
                <p className="footer_text">Share it to social media</p>
                <div className="social_meadia_handles">
                    <div className="social insta"><i class="fab fa-instagram"></i></div>
                    <div className="social twitter"><i class="fab fa-twitterx"></i></div>
                    <div className="social whatsapp"><i class="fab fa-whatsapp"></i></div>
                </div>
            </div>
            
        </div>
    )
}