import MicroSiteNav from "../../MicrositeNav"
import DisplayPanel from "../../DisplayPanel"
import styles from '../../../../static/styles/phyg_styles.module.css'
import ViewProjectUtility from "../../Components/ViewProjectUtility/ViewProjectUtility"
import { useState } from "react"
import { ProjectDetails } from "../../Components/ProjectDetails/ProjectDetails"

export const Phyg_LandingPage = () => {
    const [showProjectDetails, setShowProjectDetails] = useState(false)

    const nft = {
        project_name:'Shards of Polygon',
        nft_id:'OG',
        url:'https://verify.dehidden.com/phygitals/kit'
    }
    return (
        <div className={styles.landing_page}>
         <div className={styles.phygBody}>
            <div className={styles.microsite}>
            <MicroSiteNav nft={nft}  styles={styles}/>
            <div className={styles.microsite_page}>
             <DisplayPanel nft={nft} styles={styles}/>
            <div className={styles.mainpanel} > 
               { !showProjectDetails ? <ViewProjectUtility showProject ={setShowProjectDetails}/> : <ProjectDetails showProject ={setShowProjectDetails}/>}
            </div>
            </div>
            </div>
          </div>
        </div>
    )
}
